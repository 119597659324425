.bv-create-profit-modal {
    background-color: white;
    font-size: 18px;
}

.bv-create-profit-modal>header {
    padding: 16px;
    border-bottom: 1px solid #ccc;
}

.bv-create-profit-modal>.body {
    padding: 32px 16px;
}

.bv-create-profit-modal>.body .form-label {
    color: #000;
    font-size: 14px;
    margin-bottom: 4px;
    display: inline-block;
}

.bv-create-profit-modal>.body .form-control {
    color: #000;
    background-color: transparent;
    border: 1px solid #24afaf;
    border-radius: 0;
    width: 100%;
    height: 40px;
    font-size: 16px;
    outline: none;
}

.bv-create-profit-modal>.actions {
    width: 100%;
    padding: 16px;
    border-top: 1px solid #ccc;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
}

.bv-create-profit-modal>.actions>button {
    width: fit-content;
    padding: 8px;
    border-radius: 4px;
    opacity: 0.8;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 16;
}

.bv-create-profit-modal>.actions>button:disabled {
    opacity: .7;
    pointer-events: none;
}

.bv-create-profit-modal>.actions>button:hover {
    opacity: 1;
}

.bv-create-profit-modal>.actions>button:last-child {
    border: 1px solid #24afaf;
    background-color: transparent;
    color: #24afaf;
}